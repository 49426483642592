.c-general__verification-container {
    width: 75%;
}

.c-general__configuration {
    border-left: 4px solid blue;
    max-width: 30%;
    max-height: fit-content;
}

.c-display__keys {
    border: 1px solid #E8EBF0;
    border-radius: 4px;
    overflow: hidden;
    word-wrap: break-word;
    user-select: all;
}
