.c-filter-set__row:last-child > .c-filter-set__operator {
  display: none;
}
.c-filter-set__filter-set {
  display: flex;
}
.c-filter-set__filter-set > * {
  flex-grow: 1;
}
.c-filter-set__row {
  position: relative;
}
.c-filter-set__row .c-filter-set__operator select {
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.c-filter-row .field.field {
  margin-bottom: 0;
}
.c-filter-row {
  width: 100%;
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 1em;
}
.c-filter-set__row-container {
  border-left: 6px solid #E8EBF0;
  margin-left: 3em;
}
.c-filter-set__row-container .c-filter-set__row-container {
  background: #F6F8FB;
}
.c-filter-set__operator {
  margin-left: -3em;
}
.c-filter-set__row-container .c-filter-set__row-container .c-filter-set__operator {
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
}
